<!-- 学生基础数据编辑 -->
<template>
    <div class="editStudentData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学生基础数据编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="display: flex;justify-content: space-around;">
            <div class="" style="flex: 1;">
                <!-- 表单部分 -->
                <el-form ref="form" :model="form" :rules="rules" id="form-1" class="form" inline label-width="100px">
                    <el-form-item label="学员编号">
                        <el-input v-model="form.number" placeholder="请输入学员编号"></el-input>
                    </el-form-item>
                    <el-form-item label="学员姓名" prop="name">
                        <el-input v-model="form.name" placeholder="请输入学员姓名" @input="ConvertName"></el-input>
                    </el-form-item>
                    <el-form-item label="拼音名">
                        <el-input v-model="form.pinyin" placeholder="请输入拼音名"></el-input>
                    </el-form-item>
                    <el-form-item label="移动电话" prop="movePhone">
                        <el-input v-model="form.movePhone" placeholder="请输入移动电话"></el-input>
                    </el-form-item>
                    <el-form-item label="助记码">
                        <el-input v-model="form.mnemonicCode" placeholder="请输入助记码"></el-input>
                    </el-form-item>
                    <el-form-item label="学员卡号">
                        <el-input v-model="form.studentCard" placeholder="请输入学员卡号"></el-input>
                    </el-form-item>
                    <el-form-item label="证件号码" >
                        <el-input v-model="form.IdNumber" placeholder="请输入证件号码"></el-input>
                    </el-form-item>
                    <el-form-item label="备用证件号码">
                        <el-input v-model="form.reserveId" placeholder="请输入备用证件号码"></el-input>
                    </el-form-item>
                    <el-form-item label="入学年份">
                        <el-date-picker v-model="form.enrollmentYear" 
                        type="year" placeholder="请选择入学年份" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="现年级">
                        <el-input v-model="form.currentGrade" placeholder="请输入现年级"></el-input>
                    </el-form-item>
                    <el-form-item label="出生日期" prop="birthday">
                        <el-date-picker v-model="form.birthday" 
                        type="date" placeholder="选择出生日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="就读学校">
                        <el-input v-model="form.attendSchool" placeholder="请输入就读学校"></el-input>
                    </el-form-item>
                    <el-form-item label="民族" prop="nation">
                        <el-input v-model="form.nation" placeholder="请输入民族"></el-input>
                    </el-form-item>
                    <el-form-item label="国籍" prop="nationality">
                        <el-input v-model="form.nationality" placeholder="请输入国籍"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <div id="sex" style="width: 300px;">
                            <el-radio-group v-model="form.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="家长姓名" prop="parentName">
                        <el-input v-model="form.parentName" placeholder="请输入家长姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="家长密码">
                        <el-input v-model="form.parentPwd" show-password placeholder="请输入家长密码"></el-input>
                    </el-form-item>
                    <el-form-item label="年龄">
                        <div id="sex" style="width: 300px;">
                            <el-radio-group v-model="form.age">
                                <el-radio :label="1">少儿</el-radio>
                                <el-radio :label="2">成人</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="家庭电话">
                        <el-input v-model="form.homePhone" placeholder="请输入家庭电话"></el-input>
                    </el-form-item>
                    <el-form-item label="账号有效期">
                        <el-date-picker v-model="form.valid" type="date"
                        value-format="yyyy-MM-dd" placeholder="请选择有效期" >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="选择学校">
                        <div id="sex" style="width: 300px;">
                            <el-radio-group v-model="form.chooseSchool">
                                <el-radio :label="1">未入学</el-radio>
                                <el-radio :label="2">试学</el-radio>
                                <el-radio :label="3">学员</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="家庭住址">
                        <el-input v-model="form.address" placeholder="请输入家庭住址"></el-input>
                    </el-form-item>
                    <el-form-item label="邮政编码">
                        <el-input v-model="form.zipCode" placeholder="请输入邮政编码"></el-input>
                    </el-form-item>
                    <el-form-item label="选择机构">
                        <div id="sex" style="width: 300px;">
                            <el-radio-group v-model="form.skillSelect">
                                <el-radio :label="1">培训</el-radio>
                                <el-radio :label="2">考试</el-radio>
                                <el-radio :label="3">两者</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="登录账号">
                        <el-input v-model="form.login" placeholder="请输入登录账号"></el-input>
                    </el-form-item>
                    <el-form-item label="学员密码">
                        <el-input v-model="form.pwd" show-password placeholder="请输入学员密码"></el-input>
                    </el-form-item>
                    <el-form-item label="QQ/微信">
                        <el-input v-model="form.QQ_WeChat" placeholder="请输入"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="相片">
                        <el-input v-model="form.photo" placeholder="请输入"></el-input>
                    </el-form-item> -->
                    <el-form-item label="联系邮件">
                        <el-input v-model="form.ContactMail" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="健康状况">
                        <el-input v-model="form.healthy" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="相关机构">
                        <el-select v-model="form.relevant" filterable placeholder="请输入并选择相关机构" :clearable="true">
                            <el-option v-for="item in form.relevantChoose" 
                            :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="爱好">
                        <el-input v-model="form.hobby" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="职务">
                        <el-input v-model="form.duties" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="其他资料">
                        <el-input v-model="form.other" class="text-data" type="textarea" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="" style="width: 173px;margin-right: 50px;">
                <span style="color: #666;margin-bottom: 7px;">照片</span>
                <el-upload class="upload-demo" drag :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload" :show-file-list="false"
                    action="https://artxt.szart.cn/api/public/index.php/api/common/upload">
                    <img v-if="this.fullurl!==''" :src="this.fullurl" class="avatar">
                    <i class="el-icon-upload" v-else></i>
                    <div class="el-upload__text" v-if="this.fullurl==''">将文件拖到此处，或
                        <span style="color: red;">
                            点击上传
                        </span>
                    </div>
                    <div class="el-upload__tip" v-if="this.fullurl==''">只能上传jpg/png文件，且不超过5M</div>
                    <!-- <div class="buttom-btn" v-if="this.fullurl!==''">
                        <el-button size="mini" class="del" type="danger" icon="el-icon-delete" @click.stop="del">
                            删除
                        </el-button>
                        <el-button size="mini" class="daochu" @click.stop="derive">
                           导出
                       </el-button>
                    </div> -->
                </el-upload>
            </div>
        </div>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    import moment from "moment"
    export default {
        data() {
            return {
                form:{
                    number: "", //学员编号
                    name: "", //学员姓名
                    pinyin: "", //拼音名
                    movePhone: "", //移动电话
                    mnemonicCode: "", //助记码
                    studentCard: "", //学员卡号
                    IdNumber: "", //证件号
                    reserveId: "", //备用证件号
                    enrollmentYear: "", //入学年份
                    currentGrade: "", //现年级
                    birthday: "", //出生日期
                    attendSchool: "", //就读学校
                    nation: "", //民族
                    nationality: "", //国籍
                    sex: 1, //性别
                    parentName: "", //家长姓名
                    parentPwd: "", //家长密码
                    age: 1, //年龄
                    homePhone: "", //家庭电话
                    valid: "", //账号有效期
                    chooseSchool: 1, //选择学校
                    address: "", //家庭住址
                    zipCode: "", //邮政编码
                    skillSelect: 1 ,//选择机构
                    login:"",//登录账号
                    pwd:"",//学员密码
                    QQ_WeChat:"",//QQ或微信
                    photo:"",//相片
                    ContactMail:"",//联系邮件
                    healthy:"",//健康状况
                    relevant:"",//相关机构
                    relevantChoose:[],//机构选项
                    hobby:"",//爱好
                    duties:"",//职务
                    other:""//其他资料
                },
                rules:{
                    name: [{
                        required: true,
                        message: '学员姓名不能为空',
                        trigger: 'blur'
                    }, ],
                    movePhone: [{
                        required: true,
                        message: '移动电话不能为空',
                        trigger: 'blur'
                    }],
                    IdNumber: [{
                        required: true,
                        message: '证件号码不能为空',
                        trigger: 'blur'
                    },{ 
                        min: 18, 
                        max: 18, 
                        message: '身份证输入有误！', 
                        trigger: 'blur' 
                    }],
                    birthday:[{
                        required: true,
                        message: '出生日期不能为空',
                        trigger: 'blur'
                    }],
                    nation:[{
                        required: true,
                        message: '民族不能为空',
                        trigger: 'blur'
                    }],
                    nationality:[{
                        required: true,
                        message: '国籍不能为空',
                        trigger: 'blur'
                    }],
                    sex:[{
                        required: true,
                        message: '性别不能为空',
                        trigger: 'blur'
                    }],
                    
                },
                imageUrl:null,//上传图片
                fullurl:"",//显示图片
                url:"",//传的图片
                bool:false
               }
            },
            created() {
                // 选择机构
                this.$request({
                    url: "/api/mechanism/list",
                    method: "POST",
                    data: {
                        page: 1,
                        limit: 1000
                    }
                }).then(res => {
                    // console.log("选择机构",res.data.list)
                    this.form.relevantChoose = res.data.list
                })
                
                
                
                    this.$request({
                        url: '/api/student/detail',
                        method: 'POST',
                        data: {
                            id: this.$route.query.id
                        }
                    }).then(res => {
                        console.log(res)
                        var data = res.data.data
                        var form = this.form
                        form.number = data.number //学员编号
                        form.name = data.username //学员姓名
                        form.pinyin = data.pinyin_name //拼音名
                        form.movePhone = data.mobile1 //移动电话
                        form.mnemonicCode = data.mcode //助记码
                        form.studentCard = data.student_card //学员卡号
                        form.IdNumber = data.idcard //证件号
                        form.reserveId = data.idcard2 //备用证件号
                        form.enrollmentYear = data.start_school //入学年份
                        form.currentGrade = data.grade //现年级
                        form.birthday = data.birthdate //出生日期
                        form.attendSchool = data.school_name //就读学校
                        form.nation = data.nation //民族
                        form.nationality = data.nation2 //国籍
                        form.sex = data.gender //性别
                        form.parentName = data.parent_name //家长姓名
                        form.parentPwd = data.parent_pwd //家长密码
                        form.age = data.age_type //年龄
                        form.homePhone = data.mobile2 //家庭电话
                        form.valid = data.effective_time //账号有效期
                        form.chooseSchool = data.school_type //选择学校
                        form.address = data.address //家庭住址
                        form.zipCode = data.post_code //邮政编码
                        form.skillSelect = parseInt(data.mechanism_type )//选择机构
                        form.login=data.student_user//学员登录账号
                        form.pwd=data.student_pwd//学员密码
                        // this.imageUrl=null
                        // data:image/jpeg;base64,
                        this.fullurl=data.head_image//头像
                        this.form.QQ_WeChat=data.wechat//微信
                        this.form.ContactMail=data.email//联系邮件
                        this.form.healthy=data.health//健康状况
                        this.form.hobby=data.hobby//爱好
                        this.form.duties=data.position//职务
                        this.form.other=data.other//其他资料
                        this.form.relevant=data.mechanism_id
                    })

                },
            methods: {
                del() { //删除相片
                    this.fullurl=''  
                },
                derive() { //导出相片

                },
                goBack() { //返回
                    this.$router.go(-1);
                },
                submitForm(form) { //提交
                    this.$refs[form].validate((valid) => {
                        if (valid) {
                            // alert('submit!');
                            var data = this.form
                            this.$request({
                                url: '/api/student/edit',
                                method: 'POST',
                                data: {
                                    number: data.number,
                                    username: data.name,
                                    pinyin_name: data.pinyin,
                                    mobile1: data.movePhone,
                                    mcode: data.mnemonicCode,
                                    student_card: data.studentCard,
                                    idcard: data.IdNumber,
                                    idcard2: data.reserveId,
                                    start_school: data.enrollmentYear,
                                    grade: data.currentGrade,
                                    birthdate: data.birthday,
                                    school_name: data.attendSchool,
                                    nation: data.nation,
                                    nation2: data.nationality,
                                    gender: data.sex,
                                    parent_name: data.parentName,
                                    parent_pwd: data.parentPwd,
                                    age_type: data.age,
                                    mobile2: data.homePhone,
                                    effective_time: data.valid,
                                    school_type: data.chooseSchool,
                                    address: data.address,
                                    post_code: data.zipCode,
                                    mechanism_type: data.skillSelect,
                                    // head_image: this.imageUrl?.url || '',
                                    head_image: this.url,
                                    student_user:data.login,
                                    student_pwd:data.pwd,
                                    id: this.$route.query.id,
                                    wechat:data.QQ_WeChat,//微信
                                    email:data.ContactMail,//联系邮件
                                    health:data.healthy,//健康状况
                                    hobby:data.hobby,//爱好
                                    position:data.duties,//职务
                                    other:data.other,//其他资料
                                    mechanism_id:data.relevant
                                }
                            }).then(res => {
                                console.log(res)
                                if (res.code == 1) {
                                    this.$message({
                                        message: '修改列表数据成功',
                                        type: 'success'
                                    })
                                    setTimeout(() => {
                                        this.goBack()
                                    }, 1500)
                                }
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                },
                resetForm(form) { //取消
                    this.$router.push({
                        path: "studentData"
                    })
                    this.$refs[form].resetFields();
                },
                // 上传图片
                handleAvatarSuccess(res, file) {
                    console.log(res, file)
                    this.imageUrl = res.data;
                    this.fullurl=this.imageUrl.fullurl
                    this.url=this.imageUrl.url
                },
                beforeAvatarUpload(file) {
                    console.log(file.type)
                    const isJPG = file.type === 'image/jpg';
                    const isJPEG = file.type === 'image/jpeg';
                    const isPNG = file.type === 'image/png';
                    const isLt5M = file.size / 1024 / 1024 < 5;
                    
                    let flag = [isJPG, isJPEG, isPNG].includes(true)
                    
                    if (!flag) {
                        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    }
                    if (!isLt5M) {
                        this.$message.error('上传头像图片大小不能超过 5MB!');
                    }
                    
                    return flag && isLt5M;
                },
                Losefocus() { //失去焦点赋值生日性别
                    // 赋值生日
                    let IdNumber = this.form.IdNumber
                    if(IdNumber.trim().length==18){
                        // console.log(IdNumber.slice(6,10)+'-'+IdNumber.slice(10,12)+'-'+IdNumber.slice(12,14))
                      this.form.birthday = IdNumber.slice(6,10)+'-'+IdNumber.slice(10,12)+'-'+IdNumber.slice(12,14)
                    }
                   
                    // 赋值性别
                    let sex = IdNumber.slice(16, 17)
                    console.log(sex)
                    if (sex) {
                        if (sex % 2 !== 0) {
                            this.form.sex = 1 //男
                        } else {
                            this.form.sex = 2 //女
                        }
                    }
                },
                ConvertName(){//转换拼音
                    // this.form.name
                    // console.log(this.$pinyin.getFullChars(this.form.name));
                    this.form.pinyin=this.$pinyin.getFullChars(this.form.name)
                    // console.log(pinyin.getCamelChars('张三'));
                }
            }
        }
</script>

<style scoped="scoped">
    .editStudentData {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    /*    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    } */

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    .el-input {
        width: 300px;
        height: 37px !important;
    }

    .el-select {
        width: 300px;
    }

    /* 性别选择按钮 */
    #form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;

    }

    #form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }

    #form-1 ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }

    /* 上传图片 */
    ::v-deep .el-upload-dragger {
        width: 173px;
        height: 211px;
    }

    .el-upload-dragger .el-icon-upload {
        margin-top: 25px;
    }

    ::v-deep .el-upload__text,
    .el-upload__tip {
        font-size: 12px;
        width: 108px;
        margin: 8px auto;
    }

    .avatar {
        width: 173px;
        height: 211px;
    }

    /* 图片 删除导出按钮 */
    .buttom-btn {
        width: 173px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 0px;
    }

    .daochu {
        margin-left: 5px;
        padding-left: 30px;
        background: url(../../assets/export.png) 8px 5px no-repeat #FF7300;
        color: #FFFFFF;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
